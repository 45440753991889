/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClient = /* GraphQL */ `
  query GetClient($clientId: Int) {
    getClient(clientId: $clientId) {
      clientId
      iamId
      wealthIds
      type
      title
      firstName
      middleName
      lastName
      dob
      lastLogin
      registered
      billableStart
      address {
        addressId
        addressLine1
        addressLine2
        suburb
        postCode
        state
        country
        createdBy
        created
        updatedBy
        updated
      }
      contactDetails {
        emailAddress
        mobileNumber
        faxNumber
        phoneNumber
        createdBy
        created
        updatedBy
        updated
      }
      moduleStates {
        previouslyCompleted
        module
        moduleState
        sentDate
        sentReminders
        startedDate
        startedReminders
        finishedDate
        expiredDate
        updatedBy
        updated
      }
      wellnessScore
      goalCount
      taskCount
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($clientId: Int) {
    getPartner(clientId: $clientId) {
      clientId
      iamId
      wealthIds
      type
      title
      firstName
      middleName
      lastName
      dob
      lastLogin
      registered
      billableStart
      address {
        addressId
        addressLine1
        addressLine2
        suburb
        postCode
        state
        country
        createdBy
        created
        updatedBy
        updated
      }
      contactDetails {
        emailAddress
        mobileNumber
        faxNumber
        phoneNumber
        createdBy
        created
        updatedBy
        updated
      }
      moduleStates {
        previouslyCompleted
        module
        moduleState
        sentDate
        sentReminders
        startedDate
        startedReminders
        finishedDate
        expiredDate
        updatedBy
        updated
      }
      wellnessScore
      goalCount
      taskCount
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getHousehold = /* GraphQL */ `
  query GetHousehold($clientId: Int) {
    getHousehold(clientId: $clientId) {
      householdId
      name
      clients {
        clientId
        iamId
        wealthIds
        type
        title
        firstName
        middleName
        lastName
        dob
        lastLogin
        registered
        billableStart
        address {
          addressId
          addressLine1
          addressLine2
          suburb
          postCode
          state
          country
          createdBy
          created
          updatedBy
          updated
        }
        contactDetails {
          emailAddress
          mobileNumber
          faxNumber
          phoneNumber
          createdBy
          created
          updatedBy
          updated
        }
        moduleStates {
          previouslyCompleted
          module
          moduleState
          sentDate
          sentReminders
          startedDate
          startedReminders
          finishedDate
          expiredDate
          updatedBy
          updated
        }
        wellnessScore
        goalCount
        taskCount
        createdBy
        created
        updatedBy
        updated
      }
      isTest
      householdPackageCode
      countryCode
      wellnessScore
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients($listClientsInput: ListClientsInput) {
    listClients(listClientsInput: $listClientsInput) {
      clients {
        clientId
        iamId
        wealthIds
        type
        title
        firstName
        middleName
        lastName
        dob
        lastLogin
        registered
        billableStart
        address {
          addressId
          addressLine1
          addressLine2
          suburb
          postCode
          state
          country
          createdBy
          created
          updatedBy
          updated
        }
        contactDetails {
          emailAddress
          mobileNumber
          faxNumber
          phoneNumber
          createdBy
          created
          updatedBy
          updated
        }
        moduleStates {
          previouslyCompleted
          module
          moduleState
          sentDate
          sentReminders
          startedDate
          startedReminders
          finishedDate
          expiredDate
          updatedBy
          updated
        }
        wellnessScore
        goalCount
        taskCount
        createdBy
        created
        updatedBy
        updated
      }
      page
      pageSize
      pages
    }
  }
`;
export const listHouseholds = /* GraphQL */ `
  query ListHouseholds($listHouseholdsInput: ListHouseholdsInput) {
    listHouseholds(listHouseholdsInput: $listHouseholdsInput) {
      households {
        householdId
        name
        clients {
          clientId
          iamId
          wealthIds
          type
          title
          firstName
          middleName
          lastName
          dob
          lastLogin
          registered
          billableStart
          address {
            addressId
            addressLine1
            addressLine2
            suburb
            postCode
            state
            country
            createdBy
            created
            updatedBy
            updated
          }
          contactDetails {
            emailAddress
            mobileNumber
            faxNumber
            phoneNumber
            createdBy
            created
            updatedBy
            updated
          }
          moduleStates {
            previouslyCompleted
            module
            moduleState
            sentDate
            sentReminders
            startedDate
            startedReminders
            finishedDate
            expiredDate
            updatedBy
            updated
          }
          wellnessScore
          goalCount
          taskCount
          createdBy
          created
          updatedBy
          updated
        }
        isTest
        householdPackageCode
        countryCode
        wellnessScore
        createdBy
        created
        updatedBy
        updated
      }
      page
      pageSize
      pages
      totalCount
    }
  }
`;
export const searchILCN = /* GraphQL */ `
  query SearchILCN($searchILCNInput: SearchILCNInput) {
    searchILCN(searchILCNInput: $searchILCNInput) {
      ilClients {
        ilcn
        clientName
        otherNames
        adviserFullName
      }
    }
  }
`;
export const listStaff = /* GraphQL */ `
  query ListStaff($listStaffInput: ListStaffInput) {
    listStaff(listStaffInput: $listStaffInput) {
      staff {
        staffId
        practiceOfficeId
        iamId
        title
        firstName
        middleName
        lastName
        role
        digitalSignatureS3Path
        contactDetails {
          emailAddress
          mobileNumber
          faxNumber
          phoneNumber
          createdBy
          created
          updatedBy
          updated
        }
        createdBy
        created
        updatedBy
        updated
      }
      page
      pageSize
      pages
      totalCount
    }
  }
`;
export const listPractices = /* GraphQL */ `
  query ListPractices($listPracticesInput: ListPracticesInput) {
    listPractices(listPracticesInput: $listPracticesInput) {
      practice {
        practiceId
        practiceCode
        dealerGroup {
          dealerGroupId
          name
          abn
          afsl
          afslName
          legalName
          createdBy
          created
          updatedBy
          updated
        }
        name
        abn
        afsl
        afslName
        legalName
        status
        ilgn
        config {
          practiceConfigId
          palette
          typography
        }
        usesExternalDomain
        practicePackageCode
        logos {
          id
          type
          logo
          createdBy
          created
          updatedBy
          updated
        }
        offices {
          practiceOfficeId
          contactDetails {
            emailAddress
            mobileNumber
            faxNumber
            phoneNumber
            createdBy
            created
            updatedBy
            updated
          }
          address {
            addressId
            addressLine1
            addressLine2
            suburb
            postCode
            state
            country
            createdBy
            created
            updatedBy
            updated
          }
          name
          createdBy
          created
          updatedBy
          updated
        }
        homeRegion
        requesterCountryCode
        createdBy
        created
        updatedBy
        updated
      }
      page
      pageSize
      pages
      totalCount
    }
  }
`;
export const generateBillableReport = /* GraphQL */ `
  query GenerateBillableReport($reportingInput: ReportingInput) {
    generateBillableReport(reportingInput: $reportingInput)
  }
`;
export const generateOnboardedReport = /* GraphQL */ `
  query GenerateOnboardedReport($reportingInput: ReportingInput) {
    generateOnboardedReport(reportingInput: $reportingInput)
  }
`;
export const generateGrowthReport = /* GraphQL */ `
  query GenerateGrowthReport($reportingInput: ReportingInput) {
    generateGrowthReport(reportingInput: $reportingInput)
  }
`;
export const generateStatusReport = /* GraphQL */ `
  query GenerateStatusReport($reportingInput: ReportingInput) {
    generateStatusReport(reportingInput: $reportingInput)
  }
`;
export const generatePracticeTrendData = /* GraphQL */ `
  query GeneratePracticeTrendData(
    $generatePracticeTrendDataInput: GeneratePracticeTrendDataInput
  ) {
    generatePracticeTrendData(
      generatePracticeTrendDataInput: $generatePracticeTrendDataInput
    )
  }
`;
export const generateAdviserTrendData = /* GraphQL */ `
  query GenerateAdviserTrendData(
    $generateAdviserTrendDataInput: GenerateAdviserTrendDataInput
  ) {
    generateAdviserTrendData(
      generateAdviserTrendDataInput: $generateAdviserTrendDataInput
    )
  }
`;
export const generateHouseholdTrendData = /* GraphQL */ `
  query GenerateHouseholdTrendData(
    $generateHouseholdTrendDataInput: GenerateHouseholdTrendDataInput
  ) {
    generateHouseholdTrendData(
      generateHouseholdTrendDataInput: $generateHouseholdTrendDataInput
    )
  }
`;
export const getLatestYourLife = /* GraphQL */ `
  query GetLatestYourLife($clientId: Int) {
    getLatestYourLife(clientId: $clientId) {
      id
      clientId
      responses {
        id
        questionId
        stringResponse
        intResponse
        createdBy
        created
        updatedBy
        updated
      }
      connections {
        type
        name
        createdBy
        created
        updatedBy
        updated
      }
      notes {
        id
        householdId
        valueGroupId
        note
      }
      overallScore
      emotionalScore
      environmentalScore
      spiritualScore
      socialScore
      educationScore
      occupationalScore
      physicalScore
      financialScore
      completed
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getYourLifeHistory = /* GraphQL */ `
  query GetYourLifeHistory($args: LifeHistoryInput) {
    getYourLifeHistory(args: $args) {
      date
      lifeScore
    }
  }
`;
export const getYourValuesIntroVideoUrl = /* GraphQL */ `
  query GetYourValuesIntroVideoUrl {
    getYourValuesIntroVideoUrl {
      url
    }
  }
`;
export const getLatestYourValues = /* GraphQL */ `
  query GetLatestYourValues($clientId: Int) {
    getLatestYourValues(clientId: $clientId) {
      client {
        id
        clientId
        regrets {
          id
          regret
          createdBy
          created
          updatedBy
          updated
        }
        values {
          id
          cardId
          rank
          meaningReason
          progressAction
          createdBy
          created
          updatedBy
          updated
        }
        createdBy
        created
        updatedBy
        updated
      }
      partner {
        id
        clientId
        regrets {
          id
          regret
          createdBy
          created
          updatedBy
          updated
        }
        values {
          id
          cardId
          rank
          meaningReason
          progressAction
          createdBy
          created
          updatedBy
          updated
        }
        createdBy
        created
        updatedBy
        updated
      }
      household {
        id
        householdId
        values {
          id
          cardId
          clientId
          rank
          notes
          progress
          description
          nextSteps {
            id
            title
            notes
            assignedToClientId
            createdBy
            created
            updatedBy
            updated
          }
          createdBy
          created
          updatedBy
          updated
        }
        createdBy
        created
        updatedBy
        updated
      }
      completed
    }
  }
`;
export const getLatestInvestmentPreferences = /* GraphQL */ `
  query GetLatestInvestmentPreferences($clientId: Int) {
    getLatestInvestmentPreferences(clientId: $clientId) {
      client {
        clientId
        introVideo
        customisationVideo
        customisation
        ethicalVideo
        ethical
        assetClassVideo
        allocationVideo
        allocationType
        riskVideo
        comfortableWithDownturn
        costVideo
        costDriven
        prioritisation {
          optionId
          rank
        }
        comments
        customisationVideoHelp
        customisationVideoComments
        ethicalVideoHelp
        ethicalVideoComments
        assetClassVideoHelp
        assetClassVideoComments
        allocationVideoHelp
        allocationVideoComments
        riskVideoHelp
        riskVideoComments
        riskDiversification
        riskMarketCorrection
        riskPercentageDrop
        riskCapitalProtection
        riskAccessCapital
        riskInfluencedByReturns
        riskIncreaseToMeetGoal
        riskMaxDownside
        riskInflation
        costVideoHelp
        costVideoComments
        createdBy
        created
        updatedBy
        updated
      }
      partner {
        clientId
        introVideo
        customisationVideo
        customisation
        ethicalVideo
        ethical
        assetClassVideo
        allocationVideo
        allocationType
        riskVideo
        comfortableWithDownturn
        costVideo
        costDriven
        prioritisation {
          optionId
          rank
        }
        comments
        customisationVideoHelp
        customisationVideoComments
        ethicalVideoHelp
        ethicalVideoComments
        assetClassVideoHelp
        assetClassVideoComments
        allocationVideoHelp
        allocationVideoComments
        riskVideoHelp
        riskVideoComments
        riskDiversification
        riskMarketCorrection
        riskPercentageDrop
        riskCapitalProtection
        riskAccessCapital
        riskInfluencedByReturns
        riskIncreaseToMeetGoal
        riskMaxDownside
        riskInflation
        costVideoHelp
        costVideoComments
        createdBy
        created
        updatedBy
        updated
      }
      household {
        householdId
        customisation
        customisationNotes
        ethical
        ethicalNotes
        assetClassNotes
        allocationType
        allocationNotes
        riskNotes
        riskGroup
        costNotes
        costDriven
        prioritisationNotes
        commentNotes
        createdBy
        created
        updatedBy
        updated
      }
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals($clientId: Int!) {
    listGoals(clientId: $clientId) {
      id
      title
      goalNotes
      successLooksLike
      targetDate
      rank
      supportsStrategies
      supportsClientValues
      supportsHouseholdValues
      supportsLife {
        clientId
        valueGroupId
      }
      estimatedCost
      goalConfidence
      goalProgress
      strategies
      goalImportance
      goalCategory
      goalStatus
      recurrenceType
      recurrenceInterval
      assignedToHouseholdId
      assignedToClientId
      createdForClientId
      createdBy
      created
      updatedBy
      updated
      preSignedGoalImageUrl
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks($clientId: Int!) {
    listTasks(clientId: $clientId) {
      taskId
      title
      description
      dueDate
      completedDate
      status
      assignedToClientId
      createdForHouseholdId
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($taskId: Int!) {
    getTask(taskId: $taskId) {
      taskId
      title
      description
      dueDate
      completedDate
      status
      assignedToClientId
      createdForHouseholdId
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getWealthData = /* GraphQL */ `
  query GetWealthData($wealthInput: WealthInput) {
    getWealthData(wealthInput: $wealthInput) {
      aggregateTotal
      aggregateCashTotal
      aggregateFixedIncomeTotal
      aggregateRealAssetsTotal
      aggregateEquitiesTotal
      aggregateAlternativesTotal
      aggregateOtherTotal
      aggregateMultiAssetTotal
      portfolios {
        clientIds
        name
        type
        investmentStructure
        portfolioTotal
        portfolioCashTotal
        portfolioFixedIncomeTotal
        portfolioRealAssetsTotal
        portfolioEquitiesTotal
        portfolioAlternativesTotal
        portfolioOtherTotal
        portfolioMultiAssetTotal
        holdings {
          code
          ticker
          exchange
          name
          assetTreeId
          invGroupId
          regionId
          assetClassId
          sectorId
          securityGroupId
          securityTypeId
          quantity
          closePriceDate
          closePrice
          closePriceAUD
          currencyCode
          capitalGainAUD
        }
        drilldownCsv
      }
      householdAggregate {
        householdId
        drilldownCsv
        clientAggregates {
          clientId
          drilldownCsv
        }
      }
    }
  }
`;
export const getAssetTree = /* GraphQL */ `
  query GetAssetTree {
    getAssetTree {
      assetTree {
        assetTreeId
        invGroup {
          invGroupId
          name
          description
          isGrowth
        }
        region {
          regionId
          name
          altName
          parentRegion {
            regionId
            name
            altName
          }
        }
        assetClass {
          assetClassId
          name
        }
        sector {
          sectorId
          name
        }
      }
    }
  }
`;
export const getSecurityGroups = /* GraphQL */ `
  query GetSecurityGroups {
    getSecurityGroups {
      securityGroups {
        securityGroupId
        code
        description
      }
    }
  }
`;
export const getSecurityTypes = /* GraphQL */ `
  query GetSecurityTypes {
    getSecurityTypes {
      securityTypes {
        securityTypeId
        type
        description
      }
    }
  }
`;
export const getStaff = /* GraphQL */ `
  query GetStaff($staffId: Int) {
    getStaff(staffId: $staffId) {
      staffId
      practiceOfficeId
      iamId
      title
      firstName
      middleName
      lastName
      role
      digitalSignatureS3Path
      contactDetails {
        emailAddress
        mobileNumber
        faxNumber
        phoneNumber
        createdBy
        created
        updatedBy
        updated
      }
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getLatestDiscovery = /* GraphQL */ `
  query GetLatestDiscovery($householdId: Int) {
    getLatestDiscovery(householdId: $householdId) {
      id
      householdId
      clientDiscovery {
        id
        clientId
        homeLoanOwing
        investmentDebt
        otherDebt
        super
        savings
        income
        otherAssets
        lifeInsurance
        incomeProtection
        traumaInsurance
        tpdInsurance
        desiredRetirementAge
      }
      partnerDiscovery {
        id
        clientId
        homeLoanOwing
        investmentDebt
        otherDebt
        super
        savings
        income
        otherAssets
        lifeInsurance
        incomeProtection
        traumaInsurance
        tpdInsurance
        desiredRetirementAge
      }
      householdIncome
      householdSavings
      superContributions
      superContributionsDetails
      savingsRegularity
      savingsAmount
    }
  }
`;
export const getBankingMetadata = /* GraphQL */ `
  query GetBankingMetadata($clientId: Int) {
    getBankingMetadata(clientId: $clientId) {
      clientId
      basiqUserId
      basiqAuthToken
      basiqLastError
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getAccounts = /* GraphQL */ `
  query GetAccounts($clientId: Int) {
    getAccounts(clientId: $clientId) {
      insurance {
        accountId
        basiqAccountId
        basiqConnectionId
        clientId
        accountNumber
        accountName
        accountCurrency
        accountType
        accountSubtype
        accountClassProduct
        availableRedraw
        lifeInsurance
        lifeCovered
        incomeProtection
        incomeCovered
        traumaInsurance
        traumaCovered
        businessExpenseInsurance
        businessExpenseCovered
        severityBasedInsurance
        severityBasedCovered
        tpdInsurance
        tpdCovered
        maturityDate
        fee
        instalmentAmount
        interestRate
        interestType
        nextInstalmentDate
        offsetAccountNumber
        repaymentFrequency
        repaymentType
        balance
        commencementDate
        availableFunds
        limit
        lastUpdated
        transactionIntervalsFrom
        transactionIntervalsTo
        institution
        accountHolder
        basiqDataId
        paymentAmount
        payoutPaymentAccount
        payoutDetails
        payoutWaitingPeriod
        payoutLength
        otherLoanType
        deleted
        deletedBy
        policyEndDate
        policyStatus
      }
      savings {
        accountId
        basiqAccountId
        basiqConnectionId
        clientId
        accountNumber
        accountName
        accountCurrency
        accountType
        accountSubtype
        accountClassProduct
        availableRedraw
        lifeInsurance
        lifeCovered
        incomeProtection
        incomeCovered
        traumaInsurance
        traumaCovered
        businessExpenseInsurance
        businessExpenseCovered
        severityBasedInsurance
        severityBasedCovered
        tpdInsurance
        tpdCovered
        maturityDate
        fee
        instalmentAmount
        interestRate
        interestType
        nextInstalmentDate
        offsetAccountNumber
        repaymentFrequency
        repaymentType
        balance
        commencementDate
        availableFunds
        limit
        lastUpdated
        transactionIntervalsFrom
        transactionIntervalsTo
        institution
        accountHolder
        basiqDataId
        paymentAmount
        payoutPaymentAccount
        payoutDetails
        payoutWaitingPeriod
        payoutLength
        otherLoanType
        deleted
        deletedBy
        policyEndDate
        policyStatus
      }
      debt {
        accountId
        basiqAccountId
        basiqConnectionId
        clientId
        accountNumber
        accountName
        accountCurrency
        accountType
        accountSubtype
        accountClassProduct
        availableRedraw
        lifeInsurance
        lifeCovered
        incomeProtection
        incomeCovered
        traumaInsurance
        traumaCovered
        businessExpenseInsurance
        businessExpenseCovered
        severityBasedInsurance
        severityBasedCovered
        tpdInsurance
        tpdCovered
        maturityDate
        fee
        instalmentAmount
        interestRate
        interestType
        nextInstalmentDate
        offsetAccountNumber
        repaymentFrequency
        repaymentType
        balance
        commencementDate
        availableFunds
        limit
        lastUpdated
        transactionIntervalsFrom
        transactionIntervalsTo
        institution
        accountHolder
        basiqDataId
        paymentAmount
        payoutPaymentAccount
        payoutDetails
        payoutWaitingPeriod
        payoutLength
        otherLoanType
        deleted
        deletedBy
        policyEndDate
        policyStatus
      }
    }
  }
`;
export const getInstitutions = /* GraphQL */ `
  query GetInstitutions {
    getInstitutions {
      basiqInstitutionId
      basiqInstitutionType
      basiqInstitutionName
      basiqInstitutionShortName
      basiqInstitutionCountry
      basiqInstitutionStatRetrieveAccounts
      basiqInstitutionStatRetrieveTransactions
      basiqInstitutionLogoUrl
    }
  }
`;
export const requestAffordabilityReport = /* GraphQL */ `
  query RequestAffordabilityReport($args: RequestAffordabilityReportInput) {
    requestAffordabilityReport(args: $args)
  }
`;
export const getLifeNote = /* GraphQL */ `
  query GetLifeNote($lifeNoteInput: LifeNoteInput!) {
    getLifeNote(lifeNoteInput: $lifeNoteInput) {
      id
      householdId
      valueGroupId
      note
    }
  }
`;
export const getClientWellnessHistory = /* GraphQL */ `
  query GetClientWellnessHistory(
    $getClientWellnessHistoryInput: ClientWellnessHistoryInput
  ) {
    getClientWellnessHistory(
      getClientWellnessHistoryInput: $getClientWellnessHistoryInput
    ) {
      date
      wellnessScore
    }
  }
`;
export const getHouseholdWellnessHistory = /* GraphQL */ `
  query GetHouseholdWellnessHistory(
    $getHouseholdWellnessHistoryInput: HouseholdWellnessHistoryInput
  ) {
    getHouseholdWellnessHistory(
      getHouseholdWellnessHistoryInput: $getHouseholdWellnessHistoryInput
    ) {
      date
      wellnessScore
    }
  }
`;
export const getYourVaultContents = /* GraphQL */ `
  query GetYourVaultContents(
    $getYourVaultContentsInput: GetYourVaultContentsInput
  ) {
    getYourVaultContents(
      getYourVaultContentsInput: $getYourVaultContentsInput
    ) {
      vaultFiles {
        pathAndFilename
        presignedPathAndFilename
        lastModified
        size
        status
        publishCount
      }
      page
      pageSize
      pages
      totalCount
    }
  }
`;
export const getYourVaultSharedContents = /* GraphQL */ `
  query GetYourVaultSharedContents(
    $getYourVaultContentsInput: GetYourVaultContentsInput
  ) {
    getYourVaultSharedContents(
      getYourVaultContentsInput: $getYourVaultContentsInput
    ) {
      vaultFiles {
        pathAndFilename
        presignedPathAndFilename
        lastModified
        size
        status
        publishCount
      }
      page
      pageSize
      pages
      totalCount
    }
  }
`;
export const getYourVaultFile = /* GraphQL */ `
  query GetYourVaultFile($getYourVaultFileInput: GetYourVaultFileInput) {
    getYourVaultFile(getYourVaultFileInput: $getYourVaultFileInput)
  }
`;
export const getBasiqConnections = /* GraphQL */ `
  query GetBasiqConnections($clientId: Int) {
    getBasiqConnections(clientId: $clientId) {
      basiqId
      status
      lastUsed
      institution
    }
  }
`;
export const getSubscriptionData = /* GraphQL */ `
  query GetSubscriptionData {
    getSubscriptionData {
      billingDetailsOnFile
      adviserLicenseCount
      liteHouseholdCount
      lifePlusHouseholdCount
      bestLifeHouseholdCount
      practiceManagerAdviserCount
    }
  }
`;
export const searchClientSupport = /* GraphQL */ `
  query SearchClientSupport($searchUserSupportInput: SearchUserSupportInput) {
    searchClientSupport(searchUserSupportInput: $searchUserSupportInput) {
      searchResult {
        firstName
        lastName
        email
        mobileNumber
        type
        dob
        lastLogin
        billableStart
        billableTrigger
        created
        updatedBy
        updated
      }
    }
  }
`;
export const searchPractice = /* GraphQL */ `
  query SearchPractice($searchPracticeInput: SearchPracticeInput) {
    searchPractice(searchPracticeInput: $searchPracticeInput) {
      searchResult {
        practiceName
        practiceId
        practiceAbn
        practiceLegalName
        practiceContractStart
        practiceContractPeriod
        practiceConfigID
        practicePackageID
        createBy
        created
        updatedBy
        updated
        TCVersion
      }
    }
  }
`;
export const searchPracticeOffice = /* GraphQL */ `
  query SearchPracticeOffice(
    $searchPracticeOfficeInput: SearchPracticeOfficeInput
  ) {
    searchPracticeOffice(
      searchPracticeOfficeInput: $searchPracticeOfficeInput
    ) {
      searchResult {
        practiceId
        practiceOfficeId
        addressId
        addressLine1
        addressLine2
        addressSuburb
        addressPostcode
        addressState
        addressCountry
        practiceOfficeName
        createdBy
        created
        updatedBy
        updated
        contactDetailsId
        emailAddress
        mobileNumber
      }
    }
  }
`;
export const searchStaffSupport = /* GraphQL */ `
  query SearchStaffSupport($args: SearchStaffSupportInput) {
    searchStaffSupport(args: $args) {
      searchResult {
        firstName
        lastName
        email
        mobileNumber
        role
        practiceOfficeId
      }
    }
  }
`;
export const getBestLifeScore = /* GraphQL */ `
  query GetBestLifeScore($args: GetBestLifeScoreInput) {
    getBestLifeScore(args: $args)
  }
`;
export const getDefaultScenarioBestLifeProjection = /* GraphQL */ `
  query GetDefaultScenarioBestLifeProjection(
    $args: DefaultScenarioBestLifeProjectionInput
  ) {
    getDefaultScenarioBestLifeProjection(args: $args) {
      scenarioId
      name
      timeline {
        data
      }
      chart {
        data
        schema
        timeMarkers
        dataMarkers
      }
      latestInputs {
        data {
          scenarioId
          name
          includeClient
          clientAge
          clientIncome
          clientSuperannuation
          clientSuperannuationContribution
          clientSuperannuationContributionIsInDollars
          clientSuperannuationMatchBps
          clientSuperannuationMatchEndsBps
          clientSuperannuationFee
          clientSuperannuationFeeIsInDollars
          clientSuperOther
          clientSuperOtherContribution
          clientSuperOtherContributionIsInDollars
          clientSuperOtherFee
          clientSuperOtherFeeIsInDollars
          clientRetirementAge
          clientPensionPaidFromAge
          clientLifeExpectancyAge
          includePartner
          partnerAge
          partnerIncome
          partnerSuperannuation
          partnerSuperannuationContribution
          partnerSuperannuationContributionIsInDollars
          partnerSuperannuationMatchBps
          partnerSuperannuationMatchEndsBps
          partnerSuperannuationFee
          partnerSuperannuationFeeIsInDollars
          partnerSuperOther
          partnerSuperOtherContribution
          partnerSuperOtherContributionIsInDollars
          partnerSuperOtherFee
          partnerSuperOtherFeeIsInDollars
          partnerRetirementAge
          partnerPensionPaidFromAge
          partnerLifeExpectancyAge
          businessValueGrowthPerAnnum
          businessValue
          businessEstimatedDateOfSale
          businessEstimatedSaleAmount
          businessEstimatedTaxOnSale
          householdPersonalAssets
          householdPersonalDebt
          householdProperty
          householdPropertyDebt
          householdInvestmentProperties
          householdInvestmentPropertiesDebt
          householdNonSuperInvestments
          householdNonSuperInvestmentsFee
          householdNonSuperInvestmentsFeeIsInDollars
          householdPreRetirementAvgTax
          householdPostRetirementAvgTax
          householdIncome
          householdSavings
          householdCashFlow {
            id
            year
            clientAge
            clientIncome
            clientStatus
            partnerAge
            partnerIncome
            partnerStatus
            householdIncome
            totalIncome
            incomeSaved
            incomeSpent
            reqRetirementIncome
            contribution
            withdrawal
          }
          householdCashFlowHeader
          reqRetirementIncome
          referenceModel
          bestLifeScore
          excessCashAllocations {
            allocationEnabled
            allocationType
            allocationRank
            maxAmountDollar
            maxAmountBps
          }
          reduceShortfallAllocations {
            allocationEnabled
            allocationType
            allocationRank
            maxAmountDollar
            maxAmountBps
          }
        }
      }
      report {
        data
      }
    }
  }
`;
export const getScenario = /* GraphQL */ `
  query GetScenario($args: GetScenarioInput) {
    getScenario(args: $args) {
      scenarioId
      name
      timeline {
        data
      }
      chart {
        data
        schema
        timeMarkers
        dataMarkers
      }
      latestInputs {
        data {
          scenarioId
          name
          includeClient
          clientAge
          clientIncome
          clientSuperannuation
          clientSuperannuationContribution
          clientSuperannuationContributionIsInDollars
          clientSuperannuationMatchBps
          clientSuperannuationMatchEndsBps
          clientSuperannuationFee
          clientSuperannuationFeeIsInDollars
          clientSuperOther
          clientSuperOtherContribution
          clientSuperOtherContributionIsInDollars
          clientSuperOtherFee
          clientSuperOtherFeeIsInDollars
          clientRetirementAge
          clientPensionPaidFromAge
          clientLifeExpectancyAge
          includePartner
          partnerAge
          partnerIncome
          partnerSuperannuation
          partnerSuperannuationContribution
          partnerSuperannuationContributionIsInDollars
          partnerSuperannuationMatchBps
          partnerSuperannuationMatchEndsBps
          partnerSuperannuationFee
          partnerSuperannuationFeeIsInDollars
          partnerSuperOther
          partnerSuperOtherContribution
          partnerSuperOtherContributionIsInDollars
          partnerSuperOtherFee
          partnerSuperOtherFeeIsInDollars
          partnerRetirementAge
          partnerPensionPaidFromAge
          partnerLifeExpectancyAge
          businessValueGrowthPerAnnum
          businessValue
          businessEstimatedDateOfSale
          businessEstimatedSaleAmount
          businessEstimatedTaxOnSale
          householdPersonalAssets
          householdPersonalDebt
          householdProperty
          householdPropertyDebt
          householdInvestmentProperties
          householdInvestmentPropertiesDebt
          householdNonSuperInvestments
          householdNonSuperInvestmentsFee
          householdNonSuperInvestmentsFeeIsInDollars
          householdPreRetirementAvgTax
          householdPostRetirementAvgTax
          householdIncome
          householdSavings
          householdCashFlow {
            id
            year
            clientAge
            clientIncome
            clientStatus
            partnerAge
            partnerIncome
            partnerStatus
            householdIncome
            totalIncome
            incomeSaved
            incomeSpent
            reqRetirementIncome
            contribution
            withdrawal
          }
          householdCashFlowHeader
          reqRetirementIncome
          referenceModel
          bestLifeScore
          excessCashAllocations {
            allocationEnabled
            allocationType
            allocationRank
            maxAmountDollar
            maxAmountBps
          }
          reduceShortfallAllocations {
            allocationEnabled
            allocationType
            allocationRank
            maxAmountDollar
            maxAmountBps
          }
        }
      }
      report {
        data
      }
    }
  }
`;
export const getScenarios = /* GraphQL */ `
  query GetScenarios($args: GetScenarioInput) {
    getScenarios(args: $args) {
      scenarioId
      name
      timeline {
        data
      }
      chart {
        data
        schema
        timeMarkers
        dataMarkers
      }
      latestInputs {
        data {
          scenarioId
          name
          includeClient
          clientAge
          clientIncome
          clientSuperannuation
          clientSuperannuationContribution
          clientSuperannuationContributionIsInDollars
          clientSuperannuationMatchBps
          clientSuperannuationMatchEndsBps
          clientSuperannuationFee
          clientSuperannuationFeeIsInDollars
          clientSuperOther
          clientSuperOtherContribution
          clientSuperOtherContributionIsInDollars
          clientSuperOtherFee
          clientSuperOtherFeeIsInDollars
          clientRetirementAge
          clientPensionPaidFromAge
          clientLifeExpectancyAge
          includePartner
          partnerAge
          partnerIncome
          partnerSuperannuation
          partnerSuperannuationContribution
          partnerSuperannuationContributionIsInDollars
          partnerSuperannuationMatchBps
          partnerSuperannuationMatchEndsBps
          partnerSuperannuationFee
          partnerSuperannuationFeeIsInDollars
          partnerSuperOther
          partnerSuperOtherContribution
          partnerSuperOtherContributionIsInDollars
          partnerSuperOtherFee
          partnerSuperOtherFeeIsInDollars
          partnerRetirementAge
          partnerPensionPaidFromAge
          partnerLifeExpectancyAge
          businessValueGrowthPerAnnum
          businessValue
          businessEstimatedDateOfSale
          businessEstimatedSaleAmount
          businessEstimatedTaxOnSale
          householdPersonalAssets
          householdPersonalDebt
          householdProperty
          householdPropertyDebt
          householdInvestmentProperties
          householdInvestmentPropertiesDebt
          householdNonSuperInvestments
          householdNonSuperInvestmentsFee
          householdNonSuperInvestmentsFeeIsInDollars
          householdPreRetirementAvgTax
          householdPostRetirementAvgTax
          householdIncome
          householdSavings
          householdCashFlow {
            id
            year
            clientAge
            clientIncome
            clientStatus
            partnerAge
            partnerIncome
            partnerStatus
            householdIncome
            totalIncome
            incomeSaved
            incomeSpent
            reqRetirementIncome
            contribution
            withdrawal
          }
          householdCashFlowHeader
          reqRetirementIncome
          referenceModel
          bestLifeScore
          excessCashAllocations {
            allocationEnabled
            allocationType
            allocationRank
            maxAmountDollar
            maxAmountBps
          }
          reduceShortfallAllocations {
            allocationEnabled
            allocationType
            allocationRank
            maxAmountDollar
            maxAmountBps
          }
        }
      }
      report {
        data
      }
    }
  }
`;
export const listVoiceTranscriptions = /* GraphQL */ `
  query ListVoiceTranscriptions($args: ListVoiceTranscriptionsInput) {
    listVoiceTranscriptions(args: $args) {
      voiceTranscriptions {
        id
        filename
        audioFileDownloadLink
        createdDatetime
        status
      }
      page
      pageSize
      pages
      totalCount
    }
  }
`;
export const listVoiceTranscriptionsShared = /* GraphQL */ `
  query ListVoiceTranscriptionsShared($args: ListVoiceTranscriptionsInput) {
    listVoiceTranscriptionsShared(args: $args) {
      voiceTranscriptions {
        id
        filename
        audioFileDownloadLink
        createdDatetime
        status
      }
      page
      pageSize
      pages
      totalCount
    }
  }
`;
export const getTranscriptionBundle = /* GraphQL */ `
  query GetTranscriptionBundle($args: getTranscriptionBundleInput) {
    getTranscriptionBundle(args: $args) {
      id
      filename
      audioFile
      transcriptionText
      createdDatetime
      status
    }
  }
`;
export const getAsset = /* GraphQL */ `
  query GetAsset($args: GetAssetInput!) {
    getAsset(args: $args) {
      assetId
      householdId
      clientId
      legalEntityId
      assetType
      superType
      pensionType
      description
      memberNumber
      balance
      definedBenefitSalary
      definedBenefitMultiple
      preRetirementRefModel
      postRetirementRefModel
      feesBps
      feesDollar
      addressId
      ownOrRent
      purchasePrice
      purchaseDate
      growthBps
      currentValue
      propertyType
      rent
      savingInvestmentType
      assetUse
      assessed
      make
      model
      lastValued
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getAssets = /* GraphQL */ `
  query GetAssets($args: GetAssetsInput!) {
    getAssets(args: $args) {
      assetId
      householdId
      clientId
      legalEntityId
      assetType
      superType
      pensionType
      description
      memberNumber
      balance
      definedBenefitSalary
      definedBenefitMultiple
      preRetirementRefModel
      postRetirementRefModel
      feesBps
      feesDollar
      addressId
      ownOrRent
      purchasePrice
      purchaseDate
      growthBps
      currentValue
      propertyType
      rent
      savingInvestmentType
      assetUse
      assessed
      make
      model
      lastValued
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getBeneficiary = /* GraphQL */ `
  query GetBeneficiary($args: GetBeneficiaryInput!) {
    getBeneficiary(args: $args) {
      beneficiaryId
      estatePlanningId
      firstName
      middleName
      lastName
      relationshipType
      nominatedPercentage
      ageEntitlement
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getBeneficiaries = /* GraphQL */ `
  query GetBeneficiaries($args: GetBeneficiariesInput!) {
    getBeneficiaries(args: $args) {
      beneficiaryId
      estatePlanningId
      firstName
      middleName
      lastName
      relationshipType
      nominatedPercentage
      ageEntitlement
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getEstatePlanning = /* GraphQL */ `
  query GetEstatePlanning($args: GetEstatePlanningInput!) {
    getEstatePlanning(args: $args) {
      estatePlanningId
      householdId
      clientId
      solicitorName
      willInPlace
      isWillCurrent
      willDate
      willLocation
      willProvisions
      healthcareDirective
      healthcareDirectiveDetails
      funeral_arrangement
      funeral_details
      superBeneficiariesNominated
      beneficiariesNominated
      poaOrPogGranted
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getExecutor = /* GraphQL */ `
  query GetExecutor($args: GetExecutorInput!) {
    getExecutor(args: $args) {
      executorId
      estatePlanningId
      firstName
      middleName
      lastName
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getExecutors = /* GraphQL */ `
  query GetExecutors($args: GetExecutorsInput!) {
    getExecutors(args: $args) {
      executorId
      estatePlanningId
      firstName
      middleName
      lastName
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getExpense = /* GraphQL */ `
  query GetExpense($args: GetExpenseInput!) {
    getExpense(args: $args) {
      expenseId
      householdId
      clientId
      legalEntityId
      assetId
      expenseType
      expenseSubType
      savingsPlanType
      description
      amount
      amountBps
      discretionaryAmount
      frequency
      endYear
      stopAllowance
      stopAllowanceAge
      feeIncreaseBps
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getExpenses = /* GraphQL */ `
  query GetExpenses($args: GetExpensesInput!) {
    getExpenses(args: $args) {
      expenseId
      householdId
      clientId
      legalEntityId
      assetId
      expenseType
      expenseSubType
      savingsPlanType
      description
      amount
      amountBps
      discretionaryAmount
      frequency
      endYear
      stopAllowance
      stopAllowanceAge
      feeIncreaseBps
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getHouseholdMember = /* GraphQL */ `
  query GetHouseholdMember($args: GetHouseholdMemberInput!) {
    getHouseholdMember(args: $args) {
      householdMemberId
      householdId
      relation
      firstName
      middleName
      lastName
      dob
      sex
      livingAtHome
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getHouseholdMembers = /* GraphQL */ `
  query GetHouseholdMembers($args: GetHouseholdMembersInput!) {
    getHouseholdMembers(args: $args) {
      householdMemberId
      householdId
      relation
      firstName
      middleName
      lastName
      dob
      sex
      livingAtHome
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getIncome = /* GraphQL */ `
  query GetIncome($args: GetIncomeInput!) {
    getIncome(args: $args) {
      incomeId
      householdId
      clientId
      assetId
      legalEntityId
      incomeType
      employer
      jobTitle
      salary
      salarySacrifice
      income
      frequency
      incomeSubType
      description
      assessed
      taxStatus
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getIncomes = /* GraphQL */ `
  query GetIncomes($args: GetIncomesInput!) {
    getIncomes(args: $args) {
      incomeId
      householdId
      clientId
      assetId
      legalEntityId
      incomeType
      employer
      jobTitle
      salary
      salarySacrifice
      income
      frequency
      incomeSubType
      description
      assessed
      taxStatus
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getInsurance = /* GraphQL */ `
  query GetInsurance($args: GetInsuranceInput!) {
    getInsurance(args: $args) {
      insuranceId
      householdId
      clientId
      legalEntityId
      assetId
      insuranceType
      lifeInsuranceType
      generalInsuranceType
      insurer
      cover
      insideSuper
      monthlyInsuredAmount
      ipCoverType
      arSubType
      premium
      frequency
      policyNumber
      premiumIncreaseBps
      endYear
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getInsurances = /* GraphQL */ `
  query GetInsurances($args: GetInsurancesInput!) {
    getInsurances(args: $args) {
      insuranceId
      householdId
      clientId
      legalEntityId
      assetId
      insuranceType
      lifeInsuranceType
      generalInsuranceType
      insurer
      cover
      insideSuper
      monthlyInsuredAmount
      ipCoverType
      arSubType
      premium
      frequency
      policyNumber
      premiumIncreaseBps
      endYear
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getLegalEntity = /* GraphQL */ `
  query GetLegalEntity($args: GetLegalEntityInput!) {
    getLegalEntity(args: $args) {
      legalEntityId
      householdId
      legalEntityType
      companyType
      trusteeType
      trustType
      name
      purpose
      abn
      tfn
      value
      growthBps
      growthDollar
      clientShare
      partnerShare
      clientRetirementPhase
      partnerRetirementPhase
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getLegalEntities = /* GraphQL */ `
  query GetLegalEntities($args: GetLegalEntitiesInput!) {
    getLegalEntities(args: $args) {
      legalEntityId
      householdId
      legalEntityType
      companyType
      trusteeType
      trustType
      name
      purpose
      abn
      tfn
      value
      growthBps
      growthDollar
      clientShare
      partnerShare
      clientRetirementPhase
      partnerRetirementPhase
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getLiability = /* GraphQL */ `
  query GetLiability($args: GetLiabilityInput!) {
    getLiability(args: $args) {
      liabilityId
      householdId
      clientId
      legalEntityId
      assetId
      liabilityType
      description
      lender
      maxLimit
      owing
      rate
      repayment
      repaymentFrequency
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getLiabilities = /* GraphQL */ `
  query GetLiabilities($args: GetLiabilitiesInput!) {
    getLiabilities(args: $args) {
      liabilityId
      householdId
      clientId
      legalEntityId
      assetId
      liabilityType
      description
      lender
      maxLimit
      owing
      rate
      repayment
      repaymentFrequency
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getLivingDiscretionaryExpense = /* GraphQL */ `
  query GetLivingDiscretionaryExpense(
    $args: GetLivingDiscretionaryExpenseInput!
  ) {
    getLivingDiscretionaryExpense(args: $args) {
      livingDiscretionaryExpenseId
      householdId
      rates
      water
      homeImprovement
      repairs
      electricity
      gas
      groceries
      homePhone
      broadband
      mobile
      cleaning
      cosmetics
      hairdresser
      media
      newspapers
      computers
      appliances
      clothing
      footwear
      carTransport
      publicTransport
      chemist
      coPayments
      vitamins
      childMaintenance
      livingOther
      memberships
      streaming
      alcohol
      lunchDinner
      cinema
      domesticVacations
      intlVacations
      takeAway
      gifts
      tobacco
      discretionaryOther
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getPowerOf = /* GraphQL */ `
  query GetPowerOf($args: GetPowerOfInput!) {
    getPowerOf(args: $args) {
      powerOfId
      estatePlanningId
      powerOfType
      poa_type_enduring
      poa_type_medical
      poa_type_limited
      poa_type_care
      poa_type_other
      firstName
      middleName
      lastName
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getPowerOfs = /* GraphQL */ `
  query GetPowerOfs($args: GetPowerOfsInput!) {
    getPowerOfs(args: $args) {
      powerOfId
      estatePlanningId
      powerOfType
      poa_type_enduring
      poa_type_medical
      poa_type_limited
      poa_type_care
      poa_type_other
      firstName
      middleName
      lastName
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getSuperBeneficiary = /* GraphQL */ `
  query GetSuperBeneficiary($args: GetSuperBeneficiaryInput!) {
    getSuperBeneficiary(args: $args) {
      superBeneficiaryId
      legal_entity_id
      assetId
      name
      relation
      nominated
      beneficiaryType
      ends
      amount
      percent
      estate
      estateAmount
      estateBps
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getSuperBeneficiaries = /* GraphQL */ `
  query GetSuperBeneficiaries($args: GetSuperBeneficiariesInput!) {
    getSuperBeneficiaries(args: $args) {
      superBeneficiaryId
      legal_entity_id
      assetId
      name
      relation
      nominated
      beneficiaryType
      ends
      amount
      percent
      estate
      estateAmount
      estateBps
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getTrustee = /* GraphQL */ `
  query GetTrustee($args: GetTrusteeInput!) {
    getTrustee(args: $args) {
      trusteeId
      legalEntityId
      trusteeType
      firstName
      middleName
      lastName
      companyName
      dateAppointed
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getTrustees = /* GraphQL */ `
  query GetTrustees($args: GetTrusteesInput!) {
    getTrustees(args: $args) {
      trusteeId
      legalEntityId
      trusteeType
      firstName
      middleName
      lastName
      companyName
      dateAppointed
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getTaxDetails = /* GraphQL */ `
  query GetTaxDetails($args: GetTaxDetailsInput!) {
    getTaxDetails(args: $args) {
      taxDetailsId
      clientId
      tfn
      countryCodeTax
      familyTaxBenefit
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getHealthDetails = /* GraphQL */ `
  query GetHealthDetails($args: GetHealthDetailsInput!) {
    getHealthDetails(args: $args) {
      healthDetailsId
      clientId
      healthStatus
      medicalHistoryIssues
      isSmoker
      smokePerDay
      useNicotine
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getEmploymentDetails = /* GraphQL */ `
  query GetEmploymentDetails($args: GetEmploymentDetailsInput!) {
    getEmploymentDetails(args: $args) {
      employmentDetailsId
      clientId
      degreeQualified
      qualifications
      recentRetrenched
      employmentStatus
      plannedChanges
      occupation
      startDate
      salarySacrificeAvailable
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getSensitiveDetails = /* GraphQL */ `
  query GetSensitiveDetails($args: GetSensitiveDetailsInput!) {
    getSensitiveDetails(args: $args) {
      sensitiveDetailsId
      clientId
      isPep
      isVulnerable
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getProfessionalContact = /* GraphQL */ `
  query GetProfessionalContact($args: GetProfessionalContactInput!) {
    getProfessionalContact(args: $args) {
      professionalContactId
      householdId
      contactType
      contactTypeDetails
      name
      company
      phoneNumber
      emailAddress
      address
      authorityToContact
      relationshipQuality
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getProfessionalContacts = /* GraphQL */ `
  query GetProfessionalContacts($args: GetProfessionalContactsInput!) {
    getProfessionalContacts(args: $args) {
      professionalContactId
      householdId
      contactType
      contactTypeDetails
      name
      company
      phoneNumber
      emailAddress
      address
      authorityToContact
      relationshipQuality
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getDirector = /* GraphQL */ `
  query GetDirector($args: GetDirectorInput!) {
    getDirector(args: $args) {
      directorId
      legalEntityId
      firstName
      middleName
      lastName
      dateAppointed
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getDirectors = /* GraphQL */ `
  query GetDirectors($args: GetDirectorsInput!) {
    getDirectors(args: $args) {
      directorId
      legalEntityId
      firstName
      middleName
      lastName
      dateAppointed
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getTrustBeneficiary = /* GraphQL */ `
  query GetTrustBeneficiary($args: GetTrustBeneficiaryInput!) {
    getTrustBeneficiary(args: $args) {
      trustBeneficiaryId
      legalEntityId
      firstName
      middleName
      lastName
      companyName
      nominatedPercentage
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getTrustBeneficiaries = /* GraphQL */ `
  query GetTrustBeneficiaries($args: GetTrustBeneficiariesInput!) {
    getTrustBeneficiaries(args: $args) {
      trustBeneficiaryId
      legalEntityId
      firstName
      middleName
      lastName
      companyName
      nominatedPercentage
      notes
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getValuesSession = /* GraphQL */ `
  query GetValuesSession($id: ID!) {
    getValuesSession(id: $id) {
      id
      clientId
      client {
        clientId
        regrets
        values {
          cardId
          rank
          meaningReason
          progressAction
        }
        step
        lastSeen
      }
      partner {
        clientId
        regrets
        values {
          cardId
          rank
          meaningReason
          progressAction
        }
        step
        lastSeen
      }
      adviser {
        staffId
        step
        lastSeen
      }
      support {
        staffId
        step
        lastSeen
      }
      alignedValues {
        cardId
        clientId
        rank
        notes
        progress
        description
        nextSteps {
          id
          title
          notes
          assignedToClientId
          created
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listValuesSessions = /* GraphQL */ `
  query ListValuesSessions(
    $filter: ModelValuesSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listValuesSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        client {
          clientId
          regrets
          values {
            cardId
            rank
            meaningReason
            progressAction
          }
          step
          lastSeen
        }
        partner {
          clientId
          regrets
          values {
            cardId
            rank
            meaningReason
            progressAction
          }
          step
          lastSeen
        }
        adviser {
          staffId
          step
          lastSeen
        }
        support {
          staffId
          step
          lastSeen
        }
        alignedValues {
          cardId
          clientId
          rank
          notes
          progress
          description
          nextSteps {
            id
            title
            notes
            assignedToClientId
            created
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const valuesSessionByClientId = /* GraphQL */ `
  query ValuesSessionByClientId(
    $clientId: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelValuesSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    valuesSessionByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        client {
          clientId
          regrets
          values {
            cardId
            rank
            meaningReason
            progressAction
          }
          step
          lastSeen
        }
        partner {
          clientId
          regrets
          values {
            cardId
            rank
            meaningReason
            progressAction
          }
          step
          lastSeen
        }
        adviser {
          staffId
          step
          lastSeen
        }
        support {
          staffId
          step
          lastSeen
        }
        alignedValues {
          cardId
          clientId
          rank
          notes
          progress
          description
          nextSteps {
            id
            title
            notes
            assignedToClientId
            created
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvestmentPreferencesSession = /* GraphQL */ `
  query GetInvestmentPreferencesSession($id: ID!) {
    getInvestmentPreferencesSession(id: $id) {
      id
      clientId
      inviteCode
      fullName
      status
      introVideo
      customisationVideo
      customisation
      ethicalVideo
      ethical
      assetClassVideo
      allocationVideo
      allocationType
      riskVideo
      comfortableWithDownturn
      costVideo
      costDriven
      prioritisation {
        optionId
        rank
      }
      comments
      customisationVideoHelp
      customisationVideoComments
      ethicalVideoHelp
      ethicalVideoComments
      assetClassVideoHelp
      assetClassVideoComments
      allocationVideoHelp
      allocationVideoComments
      riskVideoHelp
      riskVideoComments
      riskDiversification
      riskMarketCorrection
      riskPercentageDrop
      riskCapitalProtection
      riskAccessCapital
      riskInfluencedByReturns
      riskIncreaseToMeetGoal
      riskMaxDownside
      riskInflation
      costVideoHelp
      costVideoComments
      createdAt
      updatedAt
    }
  }
`;
export const listInvestmentPreferencesSessions = /* GraphQL */ `
  query ListInvestmentPreferencesSessions(
    $filter: ModelInvestmentPreferencesSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvestmentPreferencesSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        inviteCode
        fullName
        status
        introVideo
        customisationVideo
        customisation
        ethicalVideo
        ethical
        assetClassVideo
        allocationVideo
        allocationType
        riskVideo
        comfortableWithDownturn
        costVideo
        costDriven
        prioritisation {
          optionId
          rank
        }
        comments
        customisationVideoHelp
        customisationVideoComments
        ethicalVideoHelp
        ethicalVideoComments
        assetClassVideoHelp
        assetClassVideoComments
        allocationVideoHelp
        allocationVideoComments
        riskVideoHelp
        riskVideoComments
        riskDiversification
        riskMarketCorrection
        riskPercentageDrop
        riskCapitalProtection
        riskAccessCapital
        riskInfluencedByReturns
        riskIncreaseToMeetGoal
        riskMaxDownside
        riskInflation
        costVideoHelp
        costVideoComments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const investmentPreferencesSessionByClientId = /* GraphQL */ `
  query InvestmentPreferencesSessionByClientId(
    $clientId: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestmentPreferencesSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investmentPreferencesSessionByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        inviteCode
        fullName
        status
        introVideo
        customisationVideo
        customisation
        ethicalVideo
        ethical
        assetClassVideo
        allocationVideo
        allocationType
        riskVideo
        comfortableWithDownturn
        costVideo
        costDriven
        prioritisation {
          optionId
          rank
        }
        comments
        customisationVideoHelp
        customisationVideoComments
        ethicalVideoHelp
        ethicalVideoComments
        assetClassVideoHelp
        assetClassVideoComments
        allocationVideoHelp
        allocationVideoComments
        riskVideoHelp
        riskVideoComments
        riskDiversification
        riskMarketCorrection
        riskPercentageDrop
        riskCapitalProtection
        riskAccessCapital
        riskInfluencedByReturns
        riskIncreaseToMeetGoal
        riskMaxDownside
        riskInflation
        costVideoHelp
        costVideoComments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const investmentPreferencesSessionByInviteCode = /* GraphQL */ `
  query InvestmentPreferencesSessionByInviteCode(
    $inviteCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestmentPreferencesSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investmentPreferencesSessionByInviteCode(
      inviteCode: $inviteCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        inviteCode
        fullName
        status
        introVideo
        customisationVideo
        customisation
        ethicalVideo
        ethical
        assetClassVideo
        allocationVideo
        allocationType
        riskVideo
        comfortableWithDownturn
        costVideo
        costDriven
        prioritisation {
          optionId
          rank
        }
        comments
        customisationVideoHelp
        customisationVideoComments
        ethicalVideoHelp
        ethicalVideoComments
        assetClassVideoHelp
        assetClassVideoComments
        allocationVideoHelp
        allocationVideoComments
        riskVideoHelp
        riskVideoComments
        riskDiversification
        riskMarketCorrection
        riskPercentageDrop
        riskCapitalProtection
        riskAccessCapital
        riskInfluencedByReturns
        riskIncreaseToMeetGoal
        riskMaxDownside
        riskInflation
        costVideoHelp
        costVideoComments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getYourLifeSession = /* GraphQL */ `
  query GetYourLifeSession($id: ID!) {
    getYourLifeSession(id: $id) {
      id
      clientId
      inviteCode
      householdId
      status
      firstName
      lastName
      fullName
      hasPartner
      partnerId
      includeFinancialDetails
      partnerFirstName
      partnerLastName
      partnerFullName
      responses {
        questionId
        intResponse
        stringResponse
      }
      lovedOnes
      communityConnections
      globalConnections
      createdAt
      updatedAt
    }
  }
`;
export const listYourLifeSessions = /* GraphQL */ `
  query ListYourLifeSessions(
    $filter: ModelYourLifeSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYourLifeSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        inviteCode
        householdId
        status
        firstName
        lastName
        fullName
        hasPartner
        partnerId
        includeFinancialDetails
        partnerFirstName
        partnerLastName
        partnerFullName
        responses {
          questionId
          intResponse
          stringResponse
        }
        lovedOnes
        communityConnections
        globalConnections
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const yourLifeSessionByClientId = /* GraphQL */ `
  query YourLifeSessionByClientId(
    $clientId: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelYourLifeSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    yourLifeSessionByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        inviteCode
        householdId
        status
        firstName
        lastName
        fullName
        hasPartner
        partnerId
        includeFinancialDetails
        partnerFirstName
        partnerLastName
        partnerFullName
        responses {
          questionId
          intResponse
          stringResponse
        }
        lovedOnes
        communityConnections
        globalConnections
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const yourLifeSessionByInviteCode = /* GraphQL */ `
  query YourLifeSessionByInviteCode(
    $inviteCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelYourLifeSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    yourLifeSessionByInviteCode(
      inviteCode: $inviteCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        inviteCode
        householdId
        status
        firstName
        lastName
        fullName
        hasPartner
        partnerId
        includeFinancialDetails
        partnerFirstName
        partnerLastName
        partnerFullName
        responses {
          questionId
          intResponse
          stringResponse
        }
        lovedOnes
        communityConnections
        globalConnections
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRegistration = /* GraphQL */ `
  query GetRegistration($id: ID!) {
    getRegistration(id: $id) {
      id
      clientId
      inviteCode
      fullName
      createdAt
      updatedAt
    }
  }
`;
export const listRegistrations = /* GraphQL */ `
  query ListRegistrations(
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        inviteCode
        fullName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const registrationByClientId = /* GraphQL */ `
  query RegistrationByClientId(
    $clientId: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        inviteCode
        fullName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const registrationByInviteCode = /* GraphQL */ `
  query RegistrationByInviteCode(
    $inviteCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationByInviteCode(
      inviteCode: $inviteCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        inviteCode
        fullName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEquitiseRaised = /* GraphQL */ `
  query GetEquitiseRaised {
    getEquitiseRaised
  }
`;
export const getPracticeLogos = /* GraphQL */ `
  query GetPracticeLogos($practiceCode: String) {
    getPracticeLogos(practiceCode: $practiceCode) {
      id
      type
      logo
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getUserPackage = /* GraphQL */ `
  query GetUserPackage {
    getUserPackage {
      homeRegion
      practicePackageCode
      householdPackageCode
    }
  }
`;
export const getPackages = /* GraphQL */ `
  query GetPackages {
    getPackages {
      packages {
        code
        name
        description
        usesHouseholdPackages
        components {
          code
          name
          description
          price
          numIncluded
          treatIncludedAsMin
        }
        householdPackages {
          code
          name
          description
          requiresLicense
          components {
            code
            name
            description
            price
            numIncluded
            treatIncludedAsMin
          }
        }
      }
    }
  }
`;
export const getInvestmentPreferencesVideos = /* GraphQL */ `
  query GetInvestmentPreferencesVideos($practiceCode: String) {
    getInvestmentPreferencesVideos(practiceCode: $practiceCode) {
      introVideoUrl
      customisationVideoUrl
      ethicalVideoUrl
      assetClassVideoUrl
      allocationVideoUrl
      riskVideoUrl
      costVideoUrl
    }
  }
`;
export const getPractice = /* GraphQL */ `
  query GetPractice($practiceCode: String) {
    getPractice(practiceCode: $practiceCode) {
      practiceId
      practiceCode
      dealerGroup {
        dealerGroupId
        name
        abn
        afsl
        afslName
        legalName
        createdBy
        created
        updatedBy
        updated
      }
      name
      abn
      afsl
      afslName
      legalName
      status
      ilgn
      config {
        practiceConfigId
        palette
        typography
      }
      usesExternalDomain
      practicePackageCode
      logos {
        id
        type
        logo
        createdBy
        created
        updatedBy
        updated
      }
      offices {
        practiceOfficeId
        contactDetails {
          emailAddress
          mobileNumber
          faxNumber
          phoneNumber
          createdBy
          created
          updatedBy
          updated
        }
        address {
          addressId
          addressLine1
          addressLine2
          suburb
          postCode
          state
          country
          createdBy
          created
          updatedBy
          updated
        }
        name
        createdBy
        created
        updatedBy
        updated
      }
      homeRegion
      requesterCountryCode
      createdBy
      created
      updatedBy
      updated
    }
  }
`;
export const getPracticeWhitelabelConfig = /* GraphQL */ `
  query GetPracticeWhitelabelConfig($practiceCode: String) {
    getPracticeWhitelabelConfig(practiceCode: $practiceCode) {
      palette
      typography
    }
  }
`;
export const getYourLifeIntroVideoUrl = /* GraphQL */ `
  query GetYourLifeIntroVideoUrl(
    $yourLifeIntroVideoUrlInput: YourLifeIntroVideoUrlInput!
  ) {
    getYourLifeIntroVideoUrl(
      yourLifeIntroVideoUrlInput: $yourLifeIntroVideoUrlInput
    ) {
      url
    }
  }
`;
export const getGoalMetadata = /* GraphQL */ `
  query GetGoalMetadata {
    getGoalMetadata {
      goalCategories {
        goalCategoryId
        goalCategory
        goalCategoryDescription
      }
      goalStatuses {
        goalStatusId
        goalStatus
      }
      goalImportances {
        goalImportanceId
        goalImportance
      }
    }
  }
`;
